$lp-orange: #f05c00;

$min-contrast-ratio: 3;

$theme-colors: (
    "primary": $lp-orange
);

.navItem {
    color: white !important;
}

html {
    background-color: white !important;
}
  
.lp-mainContainer {
    padding: 0px !important;
}

.loadedView {
    opacity: 0;
    transition: 2s opacity;
}

.unloadedView {
    opacity: 1;
}

.fade-in {
    opacity: 1;
}

.fade-out {
    opacity: 0 !important;
    transition: 2s opacity;
}

.text-primary {
    color: $lp-orange !important;
}

.lp-navbarBrand {
    font-weight: 600;
    color: $lp-orange !important;
}

.lp-contentContainer {
    margin-top: 1em;
}

code {
    font-size: 10px !important;
    line-height: 8px;
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: .3rem;
}

// $navbar-dark-active-color: $lp-orange;
$navbar-dark-toggler-border-color: $lp-orange;
$dropdown-link-active-bg: $lp-orange;

@import "~bootstrap/scss/bootstrap";